import React, { useState } from 'react';
import './shelf.css'; // Optional: for custom styles if needed

// Define types for props and state
interface ShelfProps {}

const ShelfPage: React.FC<ShelfProps> = () => {
    // State for inputs
    const [shelfHeight, setShelfHeight] = useState<number>(200);
    const [shelfWidth, setShelfWidth] = useState<number>(300);
    const [rows, setRows] = useState<number>(2);
    const [columns, setColumns] = useState<number>(2);

    // Calculate section dimensions
    const sectionHeight = shelfHeight / rows;
    const sectionWidth = shelfWidth / columns;

    return (
        <div className="shelf-container">
            <h2>Custom Shelf Builder</h2>

            <div className="inputs">
                <label>
                    Shelf Height:
                    <input
                        type="number"
                        value={shelfHeight}
                        onChange={(e) => setShelfHeight(Number(e.target.value))}
                    />
                </label>
                <label>
                    Shelf Width:
                    <input
                        type="number"
                        value={shelfWidth}
                        onChange={(e) => setShelfWidth(Number(e.target.value))}
                    />
                </label>
                <label>
                    Number of Rows:
                    <input
                        type="number"
                        value={rows}
                        onChange={(e) => setRows(Number(e.target.value))}
                    />
                </label>
                <label>
                    Number of Columns:
                    <input
                        type="number"
                        value={columns}
                        onChange={(e) => setColumns(Number(e.target.value))}
                    />
                </label>
            </div>

            {/* Render the shelf */}
            <div
                className="shelf"
                style={{
                    height: `${shelfHeight}px`,
                    width: `${shelfWidth}px`,
                    gridTemplateRows: `repeat(${rows}, 1fr)`,
                    gridTemplateColumns: `repeat(${columns}, 1fr)`,
                }}
            >
                {Array.from({ length: rows * columns }, (_, i) => (
                    <div key={i} className="shelf-section" style={{ height: `${sectionHeight}px`, width: `${sectionWidth}px` }}>
                        Section {i + 1}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShelfPage;
