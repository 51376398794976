import React from 'react';
import { Link } from 'react-router-dom';

const MainPage: React.FC = () => {
    return (
        <div>
            <h1 className="title">Odd and Limitless</h1>
            <div className="content">
                <p>TODO</p>
            </div>
            <footer>
                <p>
                    Check me out on <a href="https://github.com/f-mecklen" target="_blank" rel="noopener noreferrer">GitHub</a>
                </p>
                <p>
                    Shelf-Builder <Link to="/shelf">here</Link>
                </p>
            </footer>
        </div>
    );
};

export default MainPage;
