import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage/MainPage';
import ShelfPage from './ShelfPage/ShelfPage';

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/shelf" element={<ShelfPage />} />
            </Routes>
        </Router>
    );
};

export default App;
